/* ConfirmationPage.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f9;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .title {
    font-size: 2rem;
    color: #4A90E2;
  }
  
  .message {
    font-size: 1.2rem;
    margin-top: 20px;
    color: #555;
  }
  
  .link {
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #4A90E2;
    border: none;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .link:hover {
    background-color: #357ABD;
  }
  