/* CustomerForm.css */
.form-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 8px;
    background-color: #fff;
    font-family: Poppins;
  }
  
  .input-field, .select-field, select, input, textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; 
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
    display: block;
  }
  
  .button-submit {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .button-submit:hover {
    background-color: #45a049;
  }
  
  .privacy-link {
    text-align: center;
    margin-top: 20px; 
  }

  input[type='checkbox'] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  
  .checkbox {
    display: inline-flex;
    align-items: center;
  }
  
  .custom-checkbox {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid #000;
    margin-left: 8px;
    position: relative;
  }
  
  input[type='checkbox']:checked + .custom-checkbox::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"%3E%3Cpath d="M1 5l2 2 5-5" stroke="%23000" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E') no-repeat center center;
    background-size: 10px 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  